import type { Components } from '@mui/material'
import { alpha } from '@mui/material'

import palette from '../common/palette'

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      },
      '#__next': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      },
      main: {
        flex: 1
      },
      a: {
        color: palette.primary.dark
      },
      '.scrollbar': {
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: `${alpha('#D6DCE9', 0.3)} transparent`
        }
      },
      '&::-webkit-scrollbar': {
        borderRadius: '5px',
        width: 5,
        backgroundColor: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        background: alpha('#D6DCE9', 0.3),
        borderRadius: '5px',
        backgroundColor: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 3,
        backgroundColor: '#D9D9D9',
        ml: 1
      },
      '.grecaptcha-badge': { display: 'none' }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        border: '1px solid transparent',
        fontSize: 16,
        fontWeight: 300,
        lineHeight: '24px',
        textTransform: 'none',
        boxShadow: 'none',

        '&>.MuiTouchRipple-root': {
          borderRadius: 7
        },

        '&.MuiLoadingButton-root': {
          fontSize: 16,
          fontWeight: 300,
          lineHeight: '24px'
        },

        '&:hover': {
          boxShadow: '0px 1px 3px rgba(56, 56, 56, 0.05)'
        },
        '&.Mui-disabled': {
          backgroundColor: `${palette.background.disabled} !important`,
          border: 'none'
        }
      },
      sizeLarge: {
        minHeight: 48,
        paddingTop: 11,
        paddingBottom: 11
      },
      sizeMedium: {
        minHeight: 40,
        paddingTop: 7,
        paddingBottom: 7
      },
      sizeSmall: {
        minHeight: 36,
        paddingTop: 5,
        paddingBottom: 5
      },
      outlined: {
        borderColor: palette.stroke.main,

        '&:hover': {
          borderColor: palette.stroke.main
        },
        '&:hover, &:active, &:focus': {
          backgroundColor: palette.background.dark
        },
        '&:active, &:focus': {
          borderColor: 'inherit'
        }
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#00C98D'
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: 15,
        fontWeight: 300,

        '&.Mui-disabled': {
          backgroundColor: palette.background.disabled,

          '&>.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.border.main
          }
        },

        '&.Mui-focused': {
          '&>.MuiOutlinedInput-notchedOutline': {
            borderWidth: 1
          }
        },

        '&.MuiInputBase-multiline': {
          minHeight: '48px',
          height: '100%',
          paddingLeft: '0px'
        },

        'input::-ms-reveal': {
          display: 'none'
        },

        '& legend': {
          '& > span': {
            fontSize: '12px !important'
          }
        }
      },

      notchedOutline: {
        borderColor: palette.border.main
      },

      inputSizeSmall: {
        paddingLeft: 16
      },

      sizeSmall: {
        height: 48,
        lineHeight: '24px'
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      sizeSmall: {
        fontSize: 15,
        fontWeight: 300,
        lineHeight: '24px',
        transform: 'translate(16px, 12px) scale(1)',
        color: palette.text.secondary
      },

      shrink: {
        fontSize: 12,
        top: -2,
        color: palette.text.secondary,
        transform: 'translate(13px, -10px) scale(0.97)',

        '&.Mui-focused': {
          color: palette.text.secondary,

          '&.Mui-error': {
            color: palette.error.main
          }
        }
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        height: 40,
        width: 40,

        '&.Mui-disabled': {
          color: palette.background.disabled
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        borderColor: palette.stroke.main,
        textTransform: 'inherit',

        '&>.MuiTouchRipple-root': {
          borderRadius: 7
        },

        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
          borderRadius: 8,
          '&.Mui-selected': {
            boxShadow: '0px 0px 0px 4px rgba(0, 234, 144, 0.12)',
            background: `linear-gradient(${palette.background.default}, ${palette.background.default}) padding-box,linear-gradient(90deg, #17D3FF, #00EA90) border-box`,
            border: '1px solid transparent'
          }
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderRadius: 8,
          borderLeft: '1px solid',
          borderLeftColor: palette.stroke.main,
          marginLeft: 'inherit',
          '&.Mui-selected': {
            boxShadow: '0px 0px 0px 4px rgba(0, 234, 144, 0.12)',
            background: `linear-gradient(${palette.background.default}, ${palette.background.default}) padding-box,linear-gradient(90deg, #17D3FF, #00EA90) border-box`,
            border: '1px solid transparent'
          }
        }
      }
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#E5F9F6'
        },
        '&:hover': {
          backgroundColor: '#E5F9F6'
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#E5F9F6',

          '&:hover, &:active, &:focus': {
            backgroundColor: '#E5F9F6'
          }
        },

        '&:hover, &:active, &:focus': {
          backgroundColor: alpha('#E5F9F6', 0.5)
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-root': {
          lineHeight: '24px'
        },
        '.MuiOutlinedInput-input': {
          padding: '8.5px 10px !important',
          paddingRight: '35px !important'
        }
      },
      groupLabel: {
        color: palette.text.primary
      }
    }
  },
  MuiMenu: {
    defaultProps: {
      elevation: 0
    },
    styleOverrides: {
      paper: {
        boxShadow:
          '0px 12px 24px -4px rgba(145, 158, 171, 0.15), 0px 0px 2px rgba(199, 207, 216, 0.7) !important'
      }
    }
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        '& .MuiPagination-ul': {
          '& .MuiPaginationItem-page': {
            border: 'none',
            backgroundColor: 'inherit',
            color: palette.text.secondary,
            fontSize: '16px',

            '&.Mui-selected': {
              color: palette.primary.main
            }
          }
        }
      }
    }
  },
  MuiSwitch: {
    styleOverrides: {
      thumb: {
        border: '1px solid #D0D5DF'
      },
      switchBase: {
        '&.Mui-checked': {
          '& >.MuiSwitch-thumb': {
            border: 'none'
          }
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 15,
        color: palette.text.primary
      }
    }
  },
  MuiBadge: {
    styleOverrides: {
      badge: {
        background: 'linear-gradient(90deg, #17D3FF 0%, #00EA90 100%)',
        border: '1px solid',
        borderColor: palette.background.default,
        borderRadius: '50%'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '15px'
      }
    }
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      enterTouchDelay: 200,
      leaveTouchDelay: 3000
    },
    styleOverrides: {
      tooltip: {
        margin: '0 16px',
        backgroundColor: palette.text.third
      },
      arrow: {
        color: palette.text.third
      },
      tooltipPlacementBottom: {
        marginTop: '8px !important'
      }
    }
  },
  MuiListSubheader: {
    styleOverrides: {
      root: { lineHeight: '16px', marginTop: 8, marginBottom: 8 }
    }
  }
}

export default components
