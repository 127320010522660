export const defaultLocale = 'en-GB'
export const defaultCurrency = 'usd'

export const locales = ['en-GB']

export const languages = {
  'en-GB': 'English'
}

export const defaultCurrencyItem = {
  usd: 'US$'
}

export const languagesItems = Object.entries(languages).map(
  ([value, title]) => ({
    value,
    title
  })
)

export const defaultDateFormat = 'DD/MM/YYYY'
