import Api from 'app/services/Api'

/**
 * Sign in with email
 */
export const signInEmail = async (data: SignInDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_in/with_email', {
    data: {
      authentication: data
    },
    deserialize: true
  })

/**
 * Sign in with phone number
 */
export const signInPhoneNumber = async (data: SignInDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_in/with_phone_number', {
    data: { authentication: data },
    deserialize: true
  })

/**
 * Sign in with facebook
 */
export const signInFacebook = async (
  data: ThirdPartyAuthenticationDTO
): Promise<JWT> =>
  Api.post('/api/v1/users/sign_in/with_facebook', {
    data,
    deserialize: true
  })

/**
 * Sign in with google
 */
export const signInGoogle = async (
  data: ThirdPartyAuthenticationDTO
): Promise<JWT> =>
  Api.post('/api/v1/users/sign_in/with_google', {
    data,
    deserialize: true
  })

/**
 * Sign in with apple
 */
export const authorizeInApple = async (
  data: AppleAuthenticationDTO
): Promise<JWT> =>
  Api.post('/api/v1/users/auth_with_apple', {
    data,
    deserialize: true
  })

/**
 * Sign up with email
 */
export const signUpEmail = ({
  agent_token,
  captcha_token,
  ...user
}: SignUpDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_up/with_email', {
    data: { user, agent_token, captcha_token },
    deserialize: true
  })

/**
 * Sign up with phone number
 */
export const signUpPhoneNumber = ({
  agent_token,
  captcha_token,
  ...user
}: SignUpDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_up/with_phone_number', {
    data: { user, agent_token, captcha_token },
    deserialize: true
  })

/**
 * Sign up with Facebook
 */
export const signUpFacebook = (user: ThirdPartyRegistrationDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_up/with_facebook', {
    data: user,
    deserialize: true
  })

/**
 * Sign up with Google
 */
export const signUpGoogle = (user: ThirdPartyRegistrationDTO): Promise<JWT> =>
  Api.post('/api/v1/users/sign_up/with_google', {
    data: user,
    deserialize: true
  })

/**
 * Get user
 */
export const getUser = (): Promise<UserModel> =>
  Api.get('/api/v1/users/user', {
    deserialize: true
  })

/**
 * Get user by id
 */
export const getUserBySlug = (slug: string): Promise<UserBySlugModel> =>
  Api.get(`/api/v1/users/${slug}`, {
    deserialize: true
  })

/**
 * Update user
 */
export const updateUser = (user: SignUpDTO): Promise<void> =>
  Api.put('/api/v1/users/user', {
    data: user,
    deserialize: true
  })

/**
 * Delete user
 */
export const deleteUser = (text: string): Promise<{ message: string }> =>
  Api.del('/api/v1/users/user', {
    data: { reason: { text } }
  })

/**
 * Send reset password email or phone number
 */
export const sendResetPassword = ({
  auth_method,
  ...otherData
}: ResendCodeDTO): Promise<UserResendCodeModel> =>
  Api.post('/api/v1/users/forgot_password/send_reset_password_code', {
    data: { ...auth_method, ...otherData }
  })

/**
 * Check reset password code
 */
export const checkResetPasswordCode = (
  data: ForgotPasswordVerifyDTO
): Promise<SignedIdModel> =>
  Api.post('/api/v1/users/forgot_password/check_reset_password_code', {
    data
  })

/**
 * Resend reset password code
 */
export const resendResetPasswordCode = (data: ResetUserDTO): Promise<JWT> =>
  Api.post('/api/v1/users/forgot_password/update_password', {
    data: { reset_password: data }
  })

/**
 * Resend code verification
 */
export const resendVerificationCode = ({
  captcha_token,
  agent_token,
  auth_method
}: ResendCodeDTO): Promise<UserResendCodeModel> =>
  Api.post('/api/v1/users/account_verification/send_confirmation_code', {
    data: { auth_method: { ...auth_method }, captcha_token, agent_token }
  })

/**
 *  Check user verification code
 */
export const userVerificationCode = (data: {
  confirmation_code: string
}): Promise<JWT> =>
  Api.post('/api/v1/users/account_verification/confirm', {
    data
  })

/**
 * Update business information
 */
export const updateBusinessInformation = (
  data: BusinessInfoDTO
): Promise<void> =>
  Api.put('/api/v1/users/update_profiles/business_information', {
    data
  })

/**
 * Update contact information
 */
export const updateContactInformation = (data: ContactInfoDTO): Promise<void> =>
  Api.put('/api/v1/users/update_profiles/contact_information', {
    data
  })

/**
 * Stripe connection
 */
export const stripeAccountLink = (
  data: StripeLinkDTO
): Promise<{ url: string }> =>
  Api.post(`/api/v1/payments/connect/account_links`, {
    data
  })

/**
 * Set holiday mode
 */
export const setHolidayMode = (data: HolidayModeDTO): Promise<void> =>
  Api.put(`/api/v1/users/update_profiles/holiday_mode`, {
    data
  })

/**
 * Update password
 */
export const updatePassword = (data: UpdatePasswordDTO): Promise<void> =>
  Api.put(`/api/v1/users/update_profiles/reset_password`, {
    data
  })

/**
 * Update shipping addresses
 */
export const updateShippingAddress = (
  data: UpdateShippingAddressDTO
): Promise<void> =>
  Api.put(`/api/v1/users/update_profiles/address`, {
    data: {
      addresses: data
    }
  })

/**
 * Update business addresses
 */
export const updateBusinessAddress = (
  data: UpdateBusinessAddressDTO
): Promise<void> =>
  Api.put(`/api/v1/users/update_profiles/business_address`, {
    data: {
      business_address: data
    }
  })

/**
 * Get shipping and return rules
 */
export const getShippingReturnRules = (): Promise<UserShippingReturnRules> =>
  Api.get(`/api/v1/users/shipping_and_return_rules`, {
    deserialize: true
  })

/**
 * Create shipping and return rule
 */
export const createShippingReturnRule = (
  data: ShippingReturnRulesDTO
): Promise<UserShippingReturnRules> =>
  Api.post(`/api/v1/users/shipping_and_return_rules`, {
    data: { shipping_and_return_rule: data }
  })

/**
 * Update shipping and return rule
 */
export const updateShippingReturnRule = (
  id: string,
  data: ShippingReturnRulesDTO
): Promise<UserShippingReturnRules> =>
  Api.put(`/api/v1/users/shipping_and_return_rules/${id}`, {
    data: { shipping_and_return_rule: data },
    deserialize: true
  })

/**
 * Get currencies
 */
export const getCurrencies = (): Promise<Currency[]> =>
  Api.get('/api/v1/currencies', {
    deserialize: true
  })

/**
 * Create vehicle
 */
export const createVehicle = (params: CreateVehicleDTO): Promise<void> =>
  Api.post('/api/v1/users/vehicles', {
    data: {
      vehicle: params
    }
  })

/**
 * Delete vehicle
 */
export const deleteVehicle = (vehicleSlug: string): Promise<void> =>
  Api.del(`/api/v1/users/vehicles/${vehicleSlug}`)

/**
 * Get vehicles
 */
export const getVehicles = (params): Promise<PaginationVehicleModel> =>
  Api.get('/api/v1/users/vehicles', { params, deserialize: true })

/**
 * Get vehicle last liked and available listings
 */
export const getVehicleLikedAvailableListings = (
  vehicleSlug: string
): Promise<VehicleLikedAvailableModel> =>
  Api.get(`/api/v1/users/vehicles/${vehicleSlug}`, { deserialize: true })

/**
 * Get homepage videos
 */
export const getVideos = (): Promise<Video[]> =>
  Api.get('/api/v1/videos', {
    deserialize: true
  })

/**
 * Get client secret
 */
export const createPaymentOrders = ({
  orderId
}: {
  orderId: string
}): Promise<{ client_secret: string }> =>
  Api.post('/api/v1/payments/orders', {
    data: {
      order_id: orderId
    },
    deserialize: true
  })

/**
 * Get client secret for order
 */
export const stripeShippingPayment = ({
  orderId
}: {
  orderId: string
}): Promise<{ client_secret: string }> =>
  Api.put(`/api/v1/payments/orders/${orderId}`, {
    deserialize: true
  })

/**
 * get stripe account id
 */
export const getStripeAccountId = (
  orderId: string
): Promise<{ stripe_account_id: string }> =>
  Api.get(`/api/v1/payments/orders/${orderId}`)

/**
 * Create notification token
 */
export const createNotificationToken = (token: string): Promise<void> =>
  Api.post('/api/v1/users/push_token', {
    data: {
      user: { push_token: token }
    }
  })

/**
 * Remove notification token
 */
export const removeNotificationToken = (token: string): Promise<void> =>
  Api.put('/api/v1/users/push_token', {
    data: {
      user: { push_token: token }
    }
  })

/**
 * Get counter notifications
 */
export const getCounterNotifications = (): Promise<CounterNotificationsModel> =>
  Api.get('/api/v1/users/monitoring')

/**
 * Create marketing contact
 */
export const createMarketingContact = (
  data: CreateMarketingContactDTO
): Promise<void> => Api.post('/api/v1/users/marketing_contact', { data })

/**
 * Get listing report reasons
 */
export const getReportListingReasons = (): Promise<ReportReasonModel> =>
  Api.get('/api/v1/users/listing_reason_lookup', { deserialize: true })

/**
 * Get user report reasons
 */
export const getReportUserReasons = (): Promise<ReportReasonModel> =>
  Api.get('/api/v1/users/user_reason_lookup', { deserialize: true })

/**
 * Create report
 */
export const createReport = (data: CreateReportDTO): Promise<void> =>
  Api.post('/api/v1/users/reports', {
    data: {
      report: data
    }
  })

/**
 *  Get user reviews
 */
export const getUserReviews = (
  params: UserReviewsParams
): Promise<PaginationUserReviewsModel> =>
  Api.get(`/api/v1/reviews`, {
    params,
    deserialize: true
  })

/**
 *  Update review reply
 */
export const updateReviewReply = ({
  review_id,
  review
}: ReviewReplyDTO): Promise<void> =>
  Api.put(`/api/v1/users/reviews/${review_id}`, {
    data: { review },
    deserialize: true
  })

/**
 * Get duration
 */
export const getDuration = (): Promise<{ durations: number[] }> =>
  Api.get('/api/v1/durations', { deserialize: true })

/**
 * PayPal connection
 */
export const paypalAccountLink = (
  data: PaypalLinkDTO
): Promise<{ url: string }> =>
  Api.post(`/api/v1/payments/paypals/account_links`, {
    data: {
      account_link: data
    }
  })

/**
 * Paypal payment
 */
export const paypalPayment = (
  data: PaymentPaypalDTO
): Promise<PaymentPaypalResponse> =>
  Api.post(`/api/v1/payments/paypals/orders`, {
    data
  })

/**
 * Paypal shipping payment
 */
export const paypalShippingPayment = ({
  orderId,
  ...data
}: PaymentPaypalShippingDTO): Promise<PaymentPaypalResponse> =>
  Api.put(`/api/v1/payments/paypals/orders/${orderId}`, {
    data
  })

/**
 * Get client token for paypal
 */
export const getPaypalClientToken = (
  orderId: string
): Promise<{ client_token: string }> =>
  Api.get(`/api/v1/payments/paypals/orders/${orderId}`)

/**
 * Get merchant id for paypal
 */
export const getPaypalMerchantId = (
  orderId: string
): Promise<PaypalMerchantIdModel> =>
  Api.get(`/api/v1/payments/paypals/orders/${orderId}/merchant`)

/**
 * Get all users slugs
 */
export const getAllUsersSlugs = (): Promise<{ slugs: string[] }> =>
  Api.get('/api/v1/sitemaps/user_slugs', { deserialize: true })

/**
 * Update device notification
 */
export const updateDeviceNotification = (
  data: UpdateDeviceNotificationDTO
): Promise<void> => Api.put('/api/v1/users/device_notifications/user', { data })

/**
 * Create paypal account details
 */
export const createPaypalAccountDetails = (
  data: CreatePaypalAccountDetailsDTO
) => Api.post('/api/v1/payments/paypals/account_details', { data })

/**
 * Get guest token
 */
export const getGuestToken = (): Promise<JWT> =>
  Api.post('api/v1/guests/user', { deserialize: true })

/**
 * Update guest user password
 */
export const updateGuestUserPassword = (
  data: UpdateGuestUserPasswordDTO
): Promise<JWT> =>
  Api.post('api/v1/guests/users/update_password', {
    data: {
      reset_password: data
    },
    deserialize: true
  })

/**
 * Send admin email for missing vehicle
 */
export const sendMissingVehicle = (data: MissingVehicleDTO) =>
  Api.post('/api/v1/vehicle_missing', { data })

/**
 * Get extended session token
 */
export const extendUserSession = (): Promise<JWT> =>
  Api.post('/api/v1/users/sessions/extend', { deserialize: true })
