import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions
} from 'react-query'

import {
  getCurrencies,
  getPaypalClientToken,
  getPaypalMerchantId,
  getShippingReturnRules,
  getStripeAccountId,
  getUserBySlug,
  getUserReviews,
  getVideos
} from 'app/services/Api/user'
import Deserializer from 'app/services/Deserializer/Deserializer'
import { isOfType } from 'app/utils/helpers/type.helpers'

import { userKeys } from './user.keys'

export const userConfig = {
  getUserBySlug: {
    key: userKeys.getUserBySlug,
    request: async ({ queryKey }) => {
      const [_key, _, slug] = queryKey

      const resp = await getUserBySlug(slug)

      return resp
    }
  },
  getUserReviews: {
    key: userKeys.getUserReviews,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getUserReviews(params)

      const reviews = await Deserializer.deepDeserialize(resp.reviews)

      return { reviews, pagy: resp.pagy }
    }
  },
  getInfiniteUserReviews: {
    key: userKeys.getUserReviews,
    request: async (queryParams) => {
      const { pageParam, queryKey } = queryParams
      const [_key, _, sortParams] = queryKey

      if (!isOfType.null(pageParam)) {
        const params = {
          ...sortParams,
          page: pageParam || 1
        }

        const resp = await getUserReviews(params)

        const reviews = await Deserializer.deepDeserialize(resp.reviews)

        return { reviews, pagy: resp.pagy }
      }

      return {
        reviews: [],
        pagy: {}
      }
    }
  },
  getShippingReturnRules: {
    key: userKeys.getShippingReturnRules(),
    request: getShippingReturnRules
  },
  getVideos: {
    key: userKeys.getVideos(),
    request: getVideos
  },
  getCurrencies: {
    key: userKeys.getCurrencies(),
    request: getCurrencies
  },
  getStripeAccountId: {
    key: userKeys.getStripeAccountId,
    request: async (queryParams) => {
      const { queryKey } = queryParams
      const [_key, _, orderId] = queryKey

      const resp = await getStripeAccountId(orderId)

      return resp
    }
  },
  getPaypalClientToken: {
    key: userKeys.getPaypalClientToken,
    request: async (queryParams) => {
      const { queryKey } = queryParams
      const [_key, _, orderId] = queryKey

      const resp = await getPaypalClientToken(orderId)
      return resp
    }
  },
  getPaypalMerchantId: {
    key: userKeys.getPaypalMerchantId,
    request: async ({ queryKey }) => {
      const [_key, _, orderId] = queryKey

      const resp = await getPaypalMerchantId(orderId)

      return resp
    }
  }
}

interface UseGetUserBySlugProps {
  slug: string
  options?: UseQueryOptions<UserBySlugModel>
}

export const useGetUserBySlug = (props?: UseGetUserBySlugProps) => {
  const { slug, options } = props || {}
  const { getUserBySlug: config } = userConfig
  const state = useQuery<UserBySlugModel>(config.key(slug), config.request, {
    ...options
  })

  return state
}

interface UseGetUserReviewsProps {
  params: UserReviewsParams
  options?: UseQueryOptions<PaginationUserReviewsModel>
}

export const useGetUserReviews = (props?: UseGetUserReviewsProps) => {
  const { params, options } = props || {}
  const { getUserReviews: config } = userConfig
  const state = useQuery<PaginationUserReviewsModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetInfiniteUserReviewsProps {
  params: UserReviewsParams
  options?: UseInfiniteQueryOptions<PaginationUserReviewsModel>
}

export const useGetInfiniteUserReviews = (
  props?: UseGetInfiniteUserReviewsProps
) => {
  const { params, options } = props || {}
  const { getInfiniteUserReviews: config } = userConfig
  const state = useInfiniteQuery<PaginationUserReviewsModel>(
    config.key(params),
    config.request,
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev
    }
  )

  return state
}

interface UseGetShippingReturnRulesProps {
  options?: UseQueryOptions<UserShippingReturnRules>
}

export const useGetShippingReturnRules = (
  props?: UseGetShippingReturnRulesProps
) => {
  const { options } = props || {}
  const { getShippingReturnRules: config } = userConfig
  const state = useQuery<UserShippingReturnRules>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetHomePageVideosProps {
  options?: UseQueryOptions<Video[]>
}

export const useGetHomePageVideos = (props?: UseGetHomePageVideosProps) => {
  const { options } = props || {}
  const { getVideos: config } = userConfig
  const state = useQuery<Video[]>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetCurrenciesProps {
  options?: UseQueryOptions<Currency[]>
}

export const useGetCurrencies = (props?: UseGetCurrenciesProps) => {
  const { options } = props || {}
  const { getCurrencies: config } = userConfig
  const state = useQuery<Currency[]>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetStripeAccountIdProps {
  orderId: string
  options?: UseQueryOptions<{ stripe_account_id: string }>
}

export const useGetStripeAccountId = (props?: UseGetStripeAccountIdProps) => {
  const { orderId, options } = props || {}
  const { getStripeAccountId: config } = userConfig
  const state = useQuery<{ stripe_account_id: string }>(
    config.key(orderId || ''),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetPaypalClientTokenProps {
  orderId: string
  options?: UseQueryOptions<{ client_token: string }>
}

export const useGetPaypalClientToken = (
  props?: UseGetPaypalClientTokenProps
) => {
  const { options, orderId } = props || {}
  const { getPaypalClientToken: config } = userConfig
  const state = useQuery<{ client_token: string }>(
    config.key(orderId),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetPaypalMerchantIdProps {
  orderId: string
  options?: UseQueryOptions<PaypalMerchantIdModel>
}

export const useGetPaypalMerchantId = (props?: UseGetPaypalMerchantIdProps) => {
  const { options, orderId = '' } = props || {}
  const { getPaypalMerchantId: config } = userConfig

  const state = useQuery<PaypalMerchantIdModel>(
    config.key(orderId),
    config.request,
    { ...options }
  )

  return state
}
